@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Work+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #061219;
  background-image: linear-gradient(
    180deg,
    rgba(18, 24, 30, 0) -20%,
    #061219 95%
  );
  /* background: url("../src/assets/images/peakpx.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.my-custom-bg-class {
  background-image: url("../src/assets/images/stars.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.background-dark {
  background-image: url("../src/assets/images/background.PNG");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.inset-shadow {
  box-shadow: 0px 0px 10px 10px black inset;
}
nav {
  cursor: pointer !important;
}
